<template>
  <div>
    <div v-for="item in navList" :key="item.path">
      <router-link :to="item.path">{{ item.name }}</router-link>
    </div>
    <router-view />
  </div>
</template>
<script>
import { h5SidebarList } from '@/router/navData'
export default {
  name: 'h5',
  data() {
    return {
      navList: h5SidebarList,
    }
  },
}
</script>